<!-- View service page -->
<template>
  <div class="service">
    <h1>
      <span v-if="serviceLocation.service_provider_name">{{ serviceLocation.service_provider_name }},</span>
      {{ serviceLocation.location_name }}
      <small v-if="serviceLocation.service.is_online === 1"><i class="badge badge-info">Online Only</i></small>
    </h1>

    <p v-html="serviceLocation.service.description"></p>

    <p v-if="serviceLocation.service.referral_criteria">
      <strong>Referral Criteria:</strong>
      <span v-html="serviceLocation.service.referral_criteria"/>
    </p>

    <p v-if="serviceLocation.supported_postcodes.length">
      <strong>Postcodes to be eligible for this service</strong>
      <span v-for="(myPostcodeObj, i) in serviceLocation.supported_postcodes" :key='i'>
        <span class="ml-1 badge badge-info">{{ myPostcodeObj.postcode }} </span>
      </span>
    </p>

    <div class="mt-2 service-location-contact">
      <strong>Contact</strong><br/>
      <span v-html="serviceLocation.address"/><br v-if="serviceLocation.address"/>
      <span v-html="serviceLocation.town_city"/><br v-if="serviceLocation.town_city"/>
      <span v-html="serviceLocation.county"/><br v-if="serviceLocation.county"/>
      <span v-html="serviceLocation.post_code"/><br v-if="serviceLocation.post_code"/>

      <div v-if="serviceLocation.contact_email">
        <strong>Email: </strong>
        <span v-html="serviceLocation.contact_email"/><br v-if="serviceLocation.contact_email"/>
      </div>

      <div v-if="serviceLocation.contact_telephone">
        <strong>Telephone: </strong><span v-html="serviceLocation.contact_telephone"/><br
        v-if="serviceLocation.contact_telephone"/>
      </div>

      <p><span v-html="serviceLocation.contact_overview"/></p>
    </div>

    <p v-if="serviceLocation.opening_hours">
      <strong>Opening Hours: </strong><span v-html="serviceLocation.opening_hours"/><br
      v-if="serviceLocation.opening_hours"/>
    </p>

    <base target="_blank" />
    <p>
      <span v-if="serviceLocation.website">
        <strong>Web:</strong> <a :href="serviceLocation.website">{{ serviceLocation.website }}</a><br/>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ServiceLocation",
  props: {
    serviceLocation: Object,
  },
}
</script>

<style lang="scss" scoped>
#app {
  .service {
    padding-left: 35px;
    padding-right: 5px;
    padding-top: 34px;

    h1 {
      color: var(--primary);
      font-size: 2rem;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 0.67rem;
    }
    .service-location-contact {
      font-size: 1.2rem;
    }
  }
}
</style>
