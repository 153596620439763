<template>
  <div class="service">
    <b-container class="px-0" fluid>
      <b-row no-gutters>
        <b-col cols="12">
          <Header :title="this.getTitle()"/>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <template v-if="serviceLocation">
          <b-col md="8" order="1" order-md="2">
            <ServiceLocation v-bind:serviceLocation="serviceLocation" />
            <p class="search-results-link">
              <strong>
                <router-link :to="{ name: 'Services' }">&lt; Back to search results</router-link>
              </strong>
            </p>
          </b-col>
          <b-col md="4" order="2" order-md="1">
            <ServiceLocationMap v-bind:serviceLocation="serviceLocation" />
          </b-col>
        </template>
        <template v-else-if="hasError">
          <b-col md="12" order="1" order-md="2">
            <h1>Service not found</h1>
            <p>The requested service could not be found.</p>
            <p>
              <strong>
                <router-link :to="{ name: 'Services' }">&lt; Back to search results</router-link>
              </strong>
            </p>
          </b-col>
        </template>
        <template v-else>
          <b-col md="12" order="1" order-md="2">
            <div class="d-flex justify-content-center h-100 p-5">
              <b-spinner class="m-5" label="Loading..." variant="primary"></b-spinner>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import ServiceLocation from '@/components/ServiceLocation.vue';
import ServiceLocationMap from '@/components/ServiceLocationMap.vue';
import { mapGetters } from "vuex";
import { APPLICATION_SCOPE } from "@/config";

export default {
  name: "Service",
  components: {
    Header,
    ServiceLocationMap,
    ServiceLocation,
  },
  data() {
    return {
      serviceLocation: false,
      hasError: false,
    }
  },
  props: {
    id: String,
  },
  methods: {
    getTitle() {
      return (this.applicationScope === APPLICATION_SCOPE.FAS ? "Search for young onset dementia groups and services near you" : "Search for the Admiral Nurse Services near you");
    },
    loadServiceLocation (id) {
      return new Promise((resolve, reject) => {
        window.axios
          .get(process.env.VUE_APP_FAS_API_URL
            + '/'
            + process.env.VUE_APP_FAS_API_VERSION
            + '/locations/' + id + '?expand=service'
          )
          .then((response) => {
            this.serviceLocation = response.data;
            resolve();
          })
          .catch(e => {
            this.hasError = true;
            reject(e);
          });
      });
    },
    validateApplicationScope () {
      return new Promise((resolve, reject) => {
        if (!this.serviceLocationInScope) {
          this.serviceLocation = false;
          this.hasError = true;
          reject(new Error('Invalid application scope.'));
          return;
        }
        resolve();
      });
    }
  },
  computed: {
    ...mapGetters([
      'applicationScope',
    ]),
    allowedApplicationScopes () {
      return [
        APPLICATION_SCOPE.ALL,
        this.applicationScope,
      ];
    },
    serviceLocationInScope () {
      return this.allowedApplicationScopes.includes(this.serviceLocation.service.application_scope);
    },
  },
  mounted() {
    this.loadServiceLocation(this.id)
      .then(() => this.validateApplicationScope())
      .catch(e => console.log(e.message));
  }
}
</script>

<style lang="scss" scoped>
.service {
  a {
    &:hover {
      filter: brightness(0.85);
    }
  }

  .search-results-link {
    padding-left: 35px;
  }

  h1 {
      padding-top: 34px;
  }
}
</style>
