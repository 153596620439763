<template>
  <div class="map">
    <gmaps-map :options="mapOptions">
      <gmaps-marker :options="serviceLocationMarker" />
    </gmaps-map>
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps';
import {mapGetters} from 'vuex';

export default {
  name: "ServiceLocationMap",
  props: {
    serviceLocation: Object,
  },
  components: {
    gmapsMap,
    gmapsMarker
  },
  computed: {
    ...mapGetters([
      'applicationScope',
    ]),
    searchResultMarkerIcon() {
      return require('../assets/' + this.applicationScope + '/result-icon.png');
    },
    mapOptions() {
      return {
        center: {
          ...this.serviceLocationPosition
        },
        gestureHandling: 'greedy',
        zoom: 9,
      }
    },
    serviceLocationPosition() {
      return {
        lat: parseFloat(this.serviceLocation.lat),
        lng: parseFloat(this.serviceLocation.long)
      }
    },
    serviceLocationMarker() {
      return {
        position: {
          ...this.serviceLocationPosition
        },
        title: this.serviceLocationTitle,
        icon: this.searchResultMarkerIcon,
        label: {
          text: '1',
          color: '#ffffff',
          fontFamily: "'FSPimlicoWeb-Regular', sans-serif",
          fontSize: '1.06rem',
        },
        zIndex: 999,
      }
    },
    serviceLocationTitle() {
      if (this.serviceLocation.service_provider_name) {
        return this.serviceLocation.service_provider_name + ' - ' + this.serviceLocation.location_name;
      } else {
        return this.serviceLocation.location_name;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  height: 500px;
}
</style>
